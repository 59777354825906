import React from 'react';
import { Container, Card, Text, VStack } from "@chakra-ui/react"

export default function Disclaimer() {
  return (
    <Container maxW='8xl' my='6'>
      <VStack w='100%' spacing='4'>
        <Card p='5' w='100%'>
          <Text fontSize='xl' className='customfont'>Disclaimer</Text>
        </Card>
        <Card p='8' w='100%'>
          <VStack w='100%' spacing='4'>
            <Text>The information provided by VocalThread ("we," "us," or "our") on https://www.vocalthread.in (the "Site") is for general informational purposes only. All information on the Site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</Text>
            <Text>The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.

              We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services..</Text>
            <Text>The Site cannot and does not contain [medical/legal/fitness/other] advice. The [medical/legal/fitness/other] information is provided for general informational and educational purposes only and is not a substitute for professional advice.

              Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of [medical/legal/fitness/other] advice. The use or reliance of any information contained on this Site is solely at your own risk.</Text>
          </VStack>
        </Card>
      </VStack>
    </Container>
  )
}
