import React from 'react'
import { SimpleGrid, Text, Container, VStack, AspectRatio } from "@chakra-ui/react"
// import { useCategories } from '../../../providers/CategoriesProvider';
import { Link } from 'react-router-dom';

export default function AllCategories() {

    // const { categories } = useCategories();

    let categories =  [
        {
            "id": 1,
            "name": "Camera",
            "slug": "camera",
            "image": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_bannerde8d133c-c02d-4697-9c2c-79cc56c76c87.png",
            "banner": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner58d55cc5-7eaa-4ef8-a5fd-e7b535de33cf.png",
            "small_banner": null,
            "created_at": "2024-06-30T18:40:42.000Z",
            "updated_at": "2024-06-30T18:40:42.000Z"
        },
        {
            "id": 2,
            "name": "Bikers",
            "slug": "bikers",
            "image": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner661d9735-4cd0-4690-a7e9-4556bd938b18.png",
            "banner": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner8b783f5d-b555-42e6-a913-d466c21a1b17.png",
            "small_banner": null,
            "created_at": "2024-06-30T18:41:01.000Z",
            "updated_at": "2024-06-30T18:41:01.000Z"
        },
        {
            "id": 3,
            "name": "Sarcasm",
            "slug": "sarcasm",
            "image": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner9fa49b4c-db9b-4702-9e8d-eee060a2ba6d.png",
            "banner": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_bannerc58e7849-a905-414e-9388-9cdc3449ae88.png",
            "small_banner": null,
            "created_at": "2024-06-30T18:41:38.000Z",
            "updated_at": "2024-06-30T18:41:38.000Z"
        },
        {
            "id": 4,
            "name": "Nerds",
            "slug": "nerds",
            "image": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner1a618d28-f080-4a55-b4fc-41ded459dd73.png",
            "banner": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_bannera84c93d7-3cf8-45bc-8473-87769b3c14aa.png",
            "small_banner": null,
            "created_at": "2024-06-30T18:41:59.000Z",
            "updated_at": "2024-06-30T18:41:59.000Z"
        },
        {
            "id": 5,
            "name": "Writers",
            "slug": "writers",
            "image": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner351bf968-58a8-48ef-9e2c-43a51f7698fb.png",
            "banner": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner363812f4-6026-4bc3-9f23-4970b7910641.png",
            "small_banner": null,
            "created_at": "2024-06-30T18:42:28.000Z",
            "updated_at": "2024-06-30T18:42:28.000Z"
        },
        {
            "id": 6,
            "name": "Motivational",
            "slug": "motivational",
            "image": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner1f3cd5eb-3dc1-4f17-952b-54499983e688.png",
            "banner": "https://codecart-cdn.b-cdn.net/vocalthread/categories/cat_banner7c199ba3-1811-454c-bdd0-d15a66493f81.png",
            "small_banner": null,
            "created_at": "2024-06-30T18:42:56.000Z",
            "updated_at": "2024-06-30T18:42:56.000Z"
        }
    ]

    return (
        <>
            <Container maxW='8xl'>
                <VStack spacing='4'>
                    <Text as='h1' className='customfont' fontSize={[24, 28, 28, 30, 32]}>Our Categories</Text>
                    <Text>Our wonderful categories for you to explore.</Text>

                    <SimpleGrid alignItems='center' justifyContent='center' w='100%' columns={[3, 3, 3, 6]} spacing={[4, 4, 6, 8]}>
                        {categories.map((cat, index) => {
                            return (
                                <Link key={index} to={`/categories/${cat.slug}`}>
                                    <VStack w='100%' spacing='2'>
                                        <AspectRatio ratio={1.04} w='100%'>
                                            <img src={cat.image} alt='category' />
                                        </AspectRatio>
                                        <Text as='h2' className='customfont' fontSize={[14, 18, 18, 20, 22]}>
                                            {cat.name}
                                        </Text>
                                    </VStack>
                                </Link>
                            )
                        })}
                    </SimpleGrid>
                </VStack>
            </Container>
        </>
    )
}
